import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroContainerThumb from "../containers/trattamenti/hero-thumb"

import ReviewsContainer from "../containers/home/reviews"
import CardsContainer from "../containers/home/cards"
import "../constants/global.css"

import PreferencesContainer from "../containers/trattamenti/preferences"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs"
import { useDispatch, useSelector } from "react-redux"

import {
  fetchThumbAsync,
  selectThumbbanner,
  selectThumbChirurghi,
  selectThumbLoading,
  selectThumbPrefs,
  selectThumbReviews,
  selectThumbSearchItems,
} from "../redux/thumbSlice"
import LoadingView from "../components/LoadingView/LoadingView"

const IntimoPage = ({ location }) => {
  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "i miei capelli",
    crumbSeparator: " / ",
  })
  const dispatch = useDispatch()
  const reviews = useSelector(selectThumbReviews)
  const chirurghi = useSelector(selectThumbChirurghi)
  const prefs = useSelector(selectThumbPrefs)
  const loading = useSelector(selectThumbLoading)
  const banner = useSelector(selectThumbbanner)
  const searchItems = useSelector(selectThumbSearchItems)
  useEffect(() => {
    dispatch(fetchThumbAsync("Intimo"))
  }, [])

  if (loading) {
    return <LoadingView />
  }

  return (
    <Layout>
      <SEO title="Trattamenti" />
      <BreadCrumbs crumbs={crumbs} />
      <HeroContainerThumb searchItems={searchItems} banner={banner} />
      <PreferencesContainer center Prefs={prefs} />
      <CardsContainer doctors={chirurghi} />
      <ReviewsContainer reviews={reviews} />
      <div style={{ height: 100 }} />
    </Layout>
  )
}

export default IntimoPage
